<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Holidays Page'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Holidays Page Create'"
      :title="'Holidays Page Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Name (for URL)</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'seoName'"
              :label="'seo name'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>

          <p class="form-create__label">Link (url)</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'link'"
              :label="'link'"
              :type="'text'"
              :placeholder="'Link (url)'"
              :error-messages="validationErrors['link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Link Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'link_name'"
              :label="'link_name'"
              :type="'text'"
              :placeholder="'Link Name'"
              :error-messages="validationErrors['link_name']"
              isBordered
            />
          </div>

          <p class="form-create__label">Templates (drive id via enter)</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'templates'"
              :label="'templates'"
              :placeholder="'Templates'"
              :error-messages="validationErrors['templates']"
              isBordered
            />
          </div>
          <div v-if="validationErrors" class="form-create__error">
            <div v-for="(item, index) in Object.values(validationErrors)" :key="index">
              <div v-if="Object.keys(validationErrors)[index].includes('templates')">
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import { seoTransform } from "~/helpers/seoTransform";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "HolidaysCreate",
  metaInfo: {
    title: "Holidays Page Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
        link: "",
        link_name: "",
        templates: "",
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    InputTextarea,
    MainCheckbox,
  },
  mounted() {},
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.HOLIDAYS_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name,
        seo_name: this.formDataToSend.seo_name,
        status: this.formDataToSend.status,
        link: this.formDataToSend.link,
        link_name: this.formDataToSend.link_name,
      };
      const templates = this.formDataToSend.templates.toLowerCase().split(/\r\n|\r|\n/g);
      const templatesSend = templates.filter((str) => str.trim() !== "");
      data.templates = templatesSend;

      this.$store.dispatch("templates/createHolidays", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.HOLIDAYS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
